<template>
    <div
        class="enterpriseScreen_new"
        v-loading="loading"
        element-loading-text="拼命加载中"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.5)"
    >
        <div class="largeScreen_content">
            <header>
                <div class="filterCondition">
                    <label>选择日期：</label>
                    <el-date-picker
                        type="date"
                        v-model="time"
                        placeholder="选择日期"
                        value-format="yyyy-MM-dd"
                        @change="selectTime"
                    >
                    </el-date-picker>
                </div>
                <span class="right-txt"></span>
            </header>
            <section>
                <div class="comtemt_l">
                    <div class="comtemt_deliver" id="comtemt_deliver">
                        <div class="nav">
                            生产发货情况
                        </div>
                        <el-select
                            v-model="stationId"
                            placeholder="请选择"
                            @change="selectStation"
                        >
                            <el-option
                                v-for="item in allStationData"
                                :key="item.station_id"
                                :label="item.station_name"
                                :value="item.station_id"
                            >
                            </el-option>
                        </el-select>
                        <!--<div class="full-screen" :title="fullScreenEl?'退出全屏':'全屏展示'">-->
                        <!--    <span-->
                        <!--        :class="['iconfont',fullScreenEl?'icontuichuquanping':'iconquanping']"-->
                        <!--        @click="toggleFullScreen('comtemt_deliver')"-->
                        <!--    ></span>-->
                        <!--</div>-->
                        <div class="region_box">
                            <productionDelivery ref="ref_productionDelivery"></productionDelivery>
                        </div>
                    </div>
                </div>
                <div class="comtemt_c">
                    <div class="comtemt_map" id="comtemt_map">
                        <div class="full-screen" :title="fullScreenEl?'退出全屏':'全屏展示'">
                            <span
                                :class="['iconfont',fullScreenEl?'icontuichuquanping':'iconquanping']"
                                @click="toggleFullScreen('comtemt_map')"
                            ></span>
                        </div>
                        <div class="region_box">
                            <centerPanel ref="ref_centerPanel"></centerPanel>
                        </div>
                    </div>
                </div>
                <div class="comtemt_r">
                    <div class="comtemt_things" id="comtemt_things">
                        <div class="nav">
                            物资供应情况
                        </div>
                        <!--<div class="full-screen" :title="fullScreenEl?'退出全屏':'全屏展示'">-->
                        <!--    <span-->
                        <!--        :class="['iconfont',fullScreenEl?'icontuichuquanping':'iconquanping']"-->
                        <!--        @click="toggleFullScreen('comtemt_things')"-->
                        <!--    ></span>-->
                        <!--</div>-->
                        <div class="region_box">
                            <materialSupply ref="ref_materialSupply"></materialSupply>
                        </div>
                    </div>
                    <div class="comtemt_monitor" id="comtemt_monitor">
                        <div class="nav">
                            库存情况
                        </div>
                        <!-- <div class="full-screen" :title="fullScreenEl?'退出全屏':'全屏展示'">-->
                        <!--     <span-->
                        <!--         :class="['iconfont',fullScreenEl?'icontuichuquanping':'iconquanping']"-->
                        <!--         @click="toggleFullScreen('comtemt_monitor')"-->
                        <!--     ></span>-->
                        <!-- </div>-->
                        <div class="region_box">
                            <monitor ref="ref_monitor"></monitor>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
</template>

<script>
import util from '@/utils/util';
import './CSS/index.styl';
import largeScreenFun from './JS/index.js';

import productionDelivery from './productionDelivery.vue';
import monitor from './monitor.vue';
import materialSupply from './materialSupply.vue';
import centerPanel from './centerPanel.vue';
export default {
    name: 'enterprise-screen-new',
    components: {
        productionDelivery,
        centerPanel,
        monitor,
        materialSupply,
    },
    props: {},
    data() {
        return {
            loading: false,
            // 时间
            time: '2021-09-03',
            // 场站
            stationData: [],

            // 选择场站id
            stationId: '',
            // 所有场站
            allStationData: [
                { station_id: '', tenantId: 'T210510000006,T210510000007,T210510000010', station_name: '全部' },
            ],

            // 租户列表
            tenantList: [
                { tenantId: 'T210510000006,T210510000007,T210510000010', tenantCode: 'HZ,LH,TF', tenantName: '全部' },
                // { tenantId: 'T210510000006,T210510000007', tenantCode: 'HZ,LH', tenantName: '全部' },
                { tenantId: 'T210510000006', tenantCode: 'HZ', tenantName: '菏泽商混' },
                { tenantId: 'T210510000007', tenantCode: 'LH', tenantName: '菏泽水泥' },
                { tenantId: 'T210510000010', tenantCode: 'TF', tenantName: '曹县中联' },
            ],
            // 当前选择的租户id
            tenantId: '',
            fullScreenEl: null,
        };
    },
    computed: {},
    watch: {},
    created() {
        this.time = util.currentTimeFormat('yyyy-MM-dd');
        this.loading = true;
        this.tenantId = this.tenantList[0].tenantId;
        window.onresize = () => {
            if (this.$refs.ref_productionDelivery.barChart) {
                this.$refs.ref_productionDelivery.barChart.resize();
            }
            if (this.$refs.ref_materialSupply.myChart) {
                this.$refs.ref_materialSupply.myChart.resize();
            }
            this.$refs.ref_centerPanel.resize();
        };
    },
    mounted() {
        this.getAllStationData().then(() => {
            this.initData();
        });
        this.loopRefreshData();
    },
    destroyed() {},
    methods: {
        async initData() {
            this.$refs.ref_productionDelivery.initPage();
            this.$refs.ref_monitor.initPage();
            this.$refs.ref_materialSupply.initPage();
            this.$refs.ref_centerPanel.initPage();
            this.loading = false;
        },
        loopRefreshData() {
            // 每60秒刷新一次
            const times = 60000;
            const timerFun1 = () => {
                setTimeout( async () => {
                    await this.$refs.ref_productionDelivery.loadPage();
                    await this.$refs.ref_monitor.loadPage();
                    await this.$refs.ref_materialSupply.loadPage();
                }, times);
            };
            timerFun1();

            // 每6分钟更新地图数据
            const times2 = 6 * 60 * 1000;
            const timerFun2 = () => {
                setTimeout( async () => {
                    await this.$refs.ref_centerPanel.updateMapData();
                }, times2);
            };
            timerFun2();
        },
        // 选择日期
        selectTime() {
            this.initData();
        },
        // 选择场站
        selectStation(station_id) {
            const selectItem = this.allStationData.filter(item => item.station_id === station_id)[0];
            this.tenantId = selectItem.tenantId;
            this.initData();
        },
        async getStationData() {
            this.stationData = await largeScreenFun.getStationData('T210510000006');
        },
        async getAllStationData() {
            // ['中心南站', '中心北站', '高新区站', '鄄城1号站', '鄄城3号站', '东明中心站', '中心站', '卓越站', '建业站', '砂浆站'];
            // const list1 = await largeScreenFun.getStationData('T210510000006');
            // const list2 = await largeScreenFun.getStationData('T210510000007');
            // const list3 = await largeScreenFun.getStationData('T210510000010');
            // list1.forEach(item => {
            //     item.tenantId = 'T210510000006';
            // });
            // list2.forEach(item => {
            //     item.tenantId = 'T210510000007';
            // });
            // list3.forEach(item => {
            //     item.tenantId = 'T210510000010';
            // });
            // this.stationData = list1;
            // // this.allStationData = this.allStationData.concat(list1, list2, list3);
            this.allStationData = [
                { station_id: '', tenantId: 'T210510000006,T210510000007,T210510000010', station_name: '全部' },
                { station_id: 'OT002354', station_name: '中心北站', tenantId: 'T210510000006' },
                { station_id: 'OT002355', station_name: '中心南站', tenantId: 'T210510000006' },
                { station_id: 'OT001323', station_name: '高新区站', tenantId: 'T210510000006' },
                { station_id: 'OT001329', station_name: '鄄城1', tenantId: 'T210510000006' },
                { station_id: 'OT001331', station_name: '鄄城3', tenantId: 'T210510000006' },
                { station_id: 'OT001333', station_name: '东明中心站', tenantId: 'T210510000006' },
                { station_id: 'OT001303', station_name: '中心站', tenantId: 'T210510000010' },
                { station_id: 'OT001305', station_name: '卓越站', tenantId: 'T210510000010' },
                { station_id: 'OT001307', station_name: '建业站', tenantId: 'T210510000010' },
                { station_id: 'OT002357', station_name: '砂浆站', tenantId: 'T210510000007' },
            ];
        },
        toggleFullScreen(domId) {
            const dom = document.querySelector('#' + domId);
            if (this.fullScreenEl) {
                this.fullScreenEl = null;
                this.exitScreen(dom);
            } else {
                this.fullScreenEl = domId;
                this.fullScreen(dom);
            }
        },
        // 全屏
        fullScreen(dom) {
            const rfs = dom.requestFullScreen || dom.webkitRequestFullScreen || dom.mozRequestFullScreen || dom.msRequestFullscreen;
            if (typeof rfs !== 'undefined' && rfs) {
                rfs.call(dom);
            }
        },
        // 取消全屏
        exitScreen() {
            if (document.exitFullscreen) {
                document.exitFullscreen();
            } else if (document.mozCancelFullScreen) {
                document.mozCancelFullScreen();
            } else if (document.webkitCancelFullScreen) {
                document.webkitCancelFullScreen();
            } else if (document.msExitFullscreen) {
                document.msExitFullscreen();
            }
        },
    },
};
</script>
<style lang="stylus" scoped>

</style>
